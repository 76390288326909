import cognitoProdConfig from "./cognitoConfig.prod";
import cognitoStageConfig from "./cognitoConfig.stage";

export type CognitoConfig = {
  Auth: {
    clientId: string;
    clientSecret: string;
    basic: string;
  };
  Pool: {
    poolId: string;
  };
  baseUrl: string;
  redirectUrl: string;
  oauthProvider: string;
};

export type HostPrefix = "hub" | "possibility" | "production" | "sso" | "ssoLocal";
// const prodHosts = ["hub.gimmeit.net.au", "possibility.gimmeit.net.au"];
const env = process.env.REACT_APP_BUILD_ENV || "sandbox";

const prodConfig = {
  API_URL: "https://graphql.spendable.com.au/",
  COGNITO_USER_POOL_ID: "ap-southeast-2_88hqpWzQb",
  COGNITO_CLIENT_ID: "3hcpselig1jscp31ovulfvd2e7",
  X_API_KEY: "da2-cqfnymgvfrendn7y2jao6r3qaq",
  PUBLIC_API_URL: "https://public-graphql.spendable.com.au/",
  INTERNAL_API_KEY: "https://internal.graphql.spendable.com.au",
  S3_ASSET_BUCKET: "asset.spendable.com.au",
  S3_FILE_BUCKET: "file.spendable.com.au",
  REGION: "ap-southeast-2",
  IDENTITY_POOL_ID: "ap-southeast-2:4db81624-30e2-46f0-b017-e6d22c5c9a05",
  PARTNER_ID: "SPENDABLE"
};

const stageConfig = {
  API_URL: "https://staging.graphql.spendable.com.au/",
  COGNITO_USER_POOL_ID: "ap-southeast-2_8sCz1S3tb",
  COGNITO_CLIENT_ID: "26eagboqr5kiglrnp586n60vmr",
  X_API_KEY: "da2-b7bqsldfdreffcr6mvguktqtji",
  PUBLIC_API_URL: "https://staging.public-graphql.spendable.com.au",
  INTERNAL_API_KEY: "https://staging.internal.graphql.spendable.com.au",
  S3_ASSET_BUCKET: "staging.asset.spendable.com.au",
  S3_FILE_BUCKET: "staging.file.spendable.com.au",
  REGION: "ap-southeast-2",
  IDENTITY_POOL_ID: "ap-southeast-2:b3349110-b07b-4534-9435-5484115066c0",
  PARTNER_ID: "SPENDABLE"
};

const sandboxConfig = {
  API_URL: "https://sandbox.graphql.spendable.com.au/",
  COGNITO_USER_POOL_ID: "ap-southeast-2_s0AXSiTDA",
  COGNITO_CLIENT_ID: "2cvouupkatv9s7pcevddji05im",
  X_API_KEY: "da2-ft62zokhgrcp5cmvu632ka4ubu",
  PUBLIC_API_URL: "https://sandbox.public-graphql.spendable.com.au",
  INTERNAL_API_KEY: "https://sandbox.internal.graphql.spendable.com.au",
  S3_ASSET_BUCKET: "sandbox.asset.spendable.com.au",
  S3_FILE_BUCKET: "sandbox.file.spendable.com.au",
  REGION: "ap-southeast-2",
  IDENTITY_POOL_ID: "ap-southeast-2:f25741f9-8f38-470c-a081-ead4c061a463",
  PARTNER_ID: "SPENDABLE"
};

const ssoConfig = {
  API_URL: "https://sandbox.graphql.spendable.com.au/",
  COGNITO_USER_POOL_ID: "ap-southeast-2_v0iGo3F8T",
  COGNITO_CLIENT_ID: "5g1lkqmpgob8n7vdibqb2kjk4r",
  X_API_KEY: "da2-ft62zokhgrcp5cmvu632ka4ubu",
  PUBLIC_API_URL: "https://sandbox.public-graphql.spendable.com.au",
  INTERNAL_API_KEY: "https://sandbox.internal.graphql.spendable.com.au",
  S3_ASSET_BUCKET: "sandbox.asset.spendable.com.au",
  S3_FILE_BUCKET: "sandbox.file.spendable.com.au",
  REGION: "ap-southeast-2",
  IDENTITY_POOL_ID: "ap-southeast-2:f25741f9-8f38-470c-a081-ead4c061a463",
  PARTNER_ID: "POSSIBILITY"
};

const hostname = window.location.hostname;

let config: {
  API_URL: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_CLIENT_ID: string;
  X_API_KEY: string;
  INTERNAL_API_KEY: string;
  PUBLIC_API_URL: string;
  cognitoConfig: CognitoConfig;
  S3_ASSET_BUCKET: string;
  S3_FILE_BUCKET: string;
  REGION: string;
  IDENTITY_POOL_ID: string;
  PARTNER_ID: string;
} = {
  ...stageConfig,
  cognitoConfig: cognitoStageConfig["hub"]
};

if (env === "production") {
  const hostPrefix: HostPrefix = hostname.split("gimmeit.net.au")[0] as HostPrefix;

  config = {
    ...prodConfig,
    cognitoConfig: cognitoProdConfig[hostPrefix] as CognitoConfig
  };
} else if (env === "sso") {
  config = {
    ...ssoConfig,
    cognitoConfig: cognitoStageConfig["sso"]
  };
} else if (env === "sandbox") {
  config = {
    ...sandboxConfig,
    cognitoConfig: cognitoStageConfig["hub"]
  };
}
export default config;
