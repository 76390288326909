import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import styles from "./welcomeCard.module.scss";
import WalletSvg from "./WalletSvg";
import Modalui from "../UI/modal/Modalui";
import AddFund from "../addFund/AddFund";
import { fetchUserListAction } from "src/page/dashboard/dashboardSlice";
import { checkPermission, removeNull } from "src/utils/utils";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { CAN_ACCESS_ONBOARDING, CAN_ACT_ON_QUICK_LINKS, PARTICIPANT } from "../constant/constant";
import { useNavigate } from "react-router-dom";
import { setOnboardingProgress } from "src/page/onBoarding/onBoardingSlice";
import QuickLinks from "./QuickLinks";

interface WelcomeCardProps {
  name?: string;
}

function WelcomeCard({ name = "" }: WelcomeCardProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { users } = useAppSelector((state) => state.dashboard);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [optinalButton, setOptionalButton] = useState<boolean>(false);

  const handleAddFundClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    const data = {
      limit: 20,
      from: 0,
      role: PARTICIPANT
    };

    dispatch(
      fetchUserListAction(removeNull(data), () => {
        setOptionalButton(true);
      })
    );
  }, [dispatch]);

  const handleResumeOnboarding = () => {
    dispatch(setOnboardingProgress({ onboardingProgress: "addParticipant", clickCounter: 1 }));
    navigate("/on-boarding");
  };

  return (
    <div className={styles.welcomeCardBox}>
      <div className={styles.welcomeMessage}>
        <div className={styles.welcomeMessageIn}>
          <h1>
            Hello <span>{name}</span>
          </h1>
          <p>Welcome to the SpendAble Budget Hub</p>
        </div>
        <div className={styles.welcomeBtn}>
          <div className={styles.notification}>
            {/* <div>
              <Button variant="contained">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <circle cx="10" cy="10.5" r="4" fill="#FF6B00" />
                </svg>
                Notification
              </Button>
            </div> */}
          </div>

          <div className={styles.addFund}>
            <Modalui open={isModalOpen} handleClose={handleAddFundClick} modaluiOver={styles.addFundmodaluiOver}>
              <AddFund />
            </Modalui>
            <div>
              {optinalButton && (
                <>
                  {users.total ? (
                    <Button variant="contained" onClick={handleAddFundClick}>
                      <WalletSvg />
                      How to deposit
                    </Button>
                  ) : (
                    <>
                      {checkPermission(CAN_ACCESS_ONBOARDING) && (
                        <Button variant="contained" onClick={handleResumeOnboarding}>
                          Resume your OnBoarding
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {optinalButton && users.total > 0 && checkPermission(CAN_ACT_ON_QUICK_LINKS) && <QuickLinks />}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default WelcomeCard;
