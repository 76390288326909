import client from "../client";
import { PaginationRequest } from "src/types/commonTypes";
import internalClient from "../internalClient";

export interface TransactionRequest extends PaginationRequest {
  filter?: {
    organisation_id?: string;
    transactionType?: string;
    activityName?: string;
    activityStatus?: string;
    destinationWalletId?: string;
    walletIds?: string[];
    userIds?: string[];
    created_at?: {
      min?: string;
      max?: string;
    };
  };
  userFilter?: {
    email?: string;
  };
  walletFilter?: {
    displayId?: string;
  };
  sort?: string;
}

export const getAllTransaction = (data: TransactionRequest) => {
  return client({
    query: `query SearchActivity($input: SearchInput) { 
      search_activity(input: $input) { 
        total_pages 
        total_size 
        activity_listing { 
          data 
          activity_id 
          activity_name 
          activity_status 
          process_status 
          end_timestamp 
          start_timestamp
          created_at 
          owner_id
          receipt_available
          approval_number
        } 
      } 
    }`,
    variables: {
      input: {
        from: data.from,
        size: data.size,
        sort: data?.sort,
        filter: {
          activity_filter: {
            organisation_id: data?.filter?.organisation_id,
            transaction_type: data?.filter?.transactionType,
            activity_name: data?.filter?.activityName,
            activity_status: data?.filter?.activityStatus,
            destination_wallet_id: data?.filter?.destinationWalletId,
            user_ids: data?.filter?.userIds,
            wallet_ids: data?.filter?.walletIds,
            created_at: data?.filter?.created_at
          },
          wallet_filter: {
            display_id: data?.walletFilter?.displayId
          }
        }
      }
    }
  });
};

export const getAllInternalTransaction = (data: TransactionRequest) => {
  return internalClient({
    query: `query SearchActivity($input: SearchInput) { 
      search_activity(input: $input) { 
        total_pages 
        total_size 
        activity_listing { 
          data 
          activity_id 
          activity_name 
          activity_status 
          process_status 
          end_timestamp 
          start_timestamp
          created_at 
          owner_id
          receipt_available
          approval_number
        } 
      } 
    }`,
    variables: {
      input: {
        from: data.from,
        size: data.size,
        sort: data?.sort,
        filter: {
          activity_filter: {
            transaction_type: data?.filter?.transactionType,
            activity_name: data?.filter?.activityName,
            activity_status: data?.filter?.activityStatus,
            destination_wallet_id: data?.filter?.destinationWalletId,
            user_ids: data?.filter?.userIds,
            wallet_ids: data?.filter?.walletIds,
            created_at: data?.filter?.created_at
          },
          wallet_filter: {
            display_id: data?.walletFilter?.displayId
          }
        }
      }
    }
  });
};

export type TransactionDownloadRequest = {
  filter?: {
    organisation_id?: string;
    wallet_ids?: string[];
    user_ids?: string[];
    activity_name?: string;
    created_at?: {
      min?: string;
      max?: string;
    };
  };
  timeZone?: string;
};

export const downloadTransaction = (data: TransactionDownloadRequest) => {
  return client({
    query: `mutation download_transactions ($input: DownloadTransactionsInput) {
      download_transactions(input: $input) {
        code
        count
        request_id
        download_url
      }
    }`,
    variables: {
      input: {
        activity_filter: data?.filter,
        timezone: data?.timeZone
      }
    }
  });
};

export const downloadInternalTransaction = (data: TransactionDownloadRequest) => {
  return internalClient({
    query: `mutation download_transactions ($input: DownloadTransactionsInput) {
      download_transactions(input: $input) {
        code
        count
        request_id
        download_url
      }
    }`,
    variables: {
      input: {
        activity_filter: data?.filter,
        timezone: data?.timeZone
      }
    }
  });
};

export const mockCardPurchase = (cardId: string, amount: number) => {
  return client({
    query: `mutation MockCardPurchase($input: MockCardPurchaseInput) {
      mock_card_purchase(input: $input) {
        code
        message
      }
    }`,
    variables: {
      input: {
        card_id: cardId,
        amount
      }
    }
  });
};

export const listNote = (transactionId: string) => {
  return client({
    query: `query ListNotes($input: ListNotesInput) { 
      list_notes(input: $input, limit: 1) { 
        note { 
          note_id 
          object_id 
          payload { 
            message 
            subject 
          } 
          updated_at
        } 
      } 
    }`,
    variables: {
      input: {
        note_type: "BASIC",
        object_id: transactionId
      }
    }
  });
};

export const createNote = (transactionId: string, note: string) => {
  return client({
    query: `mutation CreateNote($input: CreateNoteInput) {
      create_note(input: $input) {
        note_id
        payload {
          message
          subject
        }
      }
    }`,
    variables: {
      input: {
        payload: {
          message: note,
          subject: ""
        },
        note_type: "BASIC",
        object_id: transactionId
      }
    }
  });
};

export const updateNote = (noteId: string, note: string) => {
  return client({
    query: `mutation UpdateNote($input: UpdateNoteInput) {
      update_note(input: $input) {
        note_id
        payload {
          message
          subject
        }
      }
    }`,
    variables: {
      input: {
        note_id: noteId,
        payload: {
          message: note,
          subject: ""
        }
      }
    }
  });
};

export const transactionPhoto = (assetType: string, transactionId: string) => {
  return client({
    query: `query ListAssets($input: ListAssetsInput) {
      list_assets(input: $input) {
        next_token
        asset {
          asset_id
          attributes {
            bucket_name
            key
          }
          asset_type
          asset_status
        }
      }
    }`,
    variables: {
      input: {
        asset_type: assetType,
        object_id: transactionId
      }
    }
  });
};

export interface searchAssetRequest extends PaginationRequest {
  date?: {
    min?: string;
    max?: string;
  };
  fileTypes?: string[];
  fileIds?: string[];
}

export const searchAsset = (data: searchAssetRequest) => {
  return internalClient({
    query: `query SearchFile($input: SearchInput) {     
        search_file(input: $input) {        
          total_pages                
          total_size                
          files {                    
            file_id             
            file_type  
            process_status               
            attributes {     
              total_rows        
              key              
              bucket_name               
              date               
              e_tag
              original_filename
            }              
          }       
        }    
      }`,
    variables: {
      input: {
        filter: {
          file_filter: {
            date: data.date,
            file_types: data.fileTypes,
            file_ids: data.fileIds
          }
        },
        size: data.size,
        from: data.from
      }
    }
  });
};

export interface searchAssetContentRequest extends PaginationRequest {
  filterContentFilter: {
    file_ids: string[];
    settlement_status?: string[];
    process_status?: string[];
  };
}

export const searchAssetContent = (data: searchAssetContentRequest) => {
  return internalClient({
    query: `query SearchFileContent($input: SearchInput) {           
        search_file_content(input: $input) {        
          total_pages                
          total_size                
          file_content_list {    
            err_message   
            content_id             
            file_id             
            file_type        
            data    
            process_status 
            link_id
            link_type     
            attributes {                
              row_num  
              novatti_amount
              spendable_amount
              ppan
              approval_code
              settlement_status     
              balance_after_deposit
	            balance_before_deposit 
              wallet_before_deposit            
            }              
          }       
        }    
      }`,
    variables: {
      input: {
        filter: {
          file_content_filter: data.filterContentFilter
        },
        size: data.size,
        from: data.from
      }
    }
  });
};

export const deletePhoto = (assetId: string) => {
  return client({
    query: `mutation DeleteAsset($input: DeleteAssetInput) {
      delete_asset(input: $input) {
        asset_id
        object_id
      }
    }`,
    variables: {
      input: {
        asset_id: assetId
      }
    }
  });
};

export interface getActivityRequest {
  activity_id?: string;
  link_id?: string;
  owner_id?: string;
}

export const getActivity = (data: getActivityRequest) => {
  return client({
    query: `query GetActivity($input: GetActivityInput) { 
      get_activity(input: $input) { 
          data 
          activity_id 
          activity_name 
          activity_status 
          process_status 
          end_timestamp 
          start_timestamp
          created_at 
          owner_id
          receipt_available
          approval_number 
      } 
    }`,
    variables: {
      input: data
    }
  });
};

export const settleTransaction = (id: string) => {
  return internalClient({
    query: `mutation processContent($input: ProcessContentInput) {
      process_content(input: $input) {
        content_id
      }
    }`,
    variables: {
      input: {
        content_id: id,
        type: "SETTLEMENT"
      }
    }
  });
};

export const updateGstAmount = (id: string, price: number) => {
  return client({
    query: `mutation updateGst($input: UpdateActivityPriceComponentInput) {
      update_activity_price_components(input: $input) {
        activity_id
      }
    }`,
    variables: {
      input: {
        activity_id: id,
        price_components: {
          gst: price
        }
      }
    }
  });
};

export interface adjustTransactionRequest {
  amount: number;
  merchantName: string;
  wallet_id: string;
  transaction_user_id: string;
  currency: string;
}

export const adjustTransaction = (data: adjustTransactionRequest) => {
  return client({
    query: `mutation adjustTransaction($input: CashTransactionInput) {
      adjust_cash_transaction(input: $input) {
        link_id
        owner_id 
      }
    }`,
    variables: {
      input: data
    }
  });
};
