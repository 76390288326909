import styles from "./photoCard.module.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useState } from "react";
import Modalui from "../UI/modal/Modalui";
import { Button, Stack } from "@mui/material";
import pdfLogo from "src/assets/images/pdf.png";
import CloseIcon from "@mui/icons-material/Close";
import swal from "sweetalert2";

export interface AssetUrl {
  id: string;
  url: string;
  assetId: string;
}

interface PhotoCardProps {
  data: AssetUrl;
  onDelete: (_assetId: string) => void;
}

export default function PhotoCard({ data, onDelete }: PhotoCardProps) {
  const [open, setOpen] = useState<boolean>(false);
  const isPdf = data.id.toLowerCase().endsWith(".pdf");
  const googleViewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(data.url)}`;

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    swal
      .fire({
        title: "Are you sure you want to delete this photo?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#1e293b",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          popup: "swal2-popup-custom"
        }
      })
      .then((result) => {
        if (result.isConfirmed) {
          onDelete(data.assetId);
          setOpen(false);
        }
      });
  };

  return (
    <article className={styles.photoCard}>
      <Modalui
        open={open}
        handleClose={() => setOpen(false)}
        modaluiOver={`${styles.modalOver} ${isPdf ? styles.modalOverPdf : ""}`}
      >
        <section className={styles.close}>
          <button
            aria-label="Close modal"
            onClick={(e) => {
              e.preventDefault();
              setOpen(false);
            }}
          >
            <CloseIcon />
          </button>
        </section>
        <section role="dialog" aria-label="modal-title" aria-describedby="modal-description">
          {isPdf ? (
            <iframe
              id="modal-description"
              aria-label="PDF view"
              src={googleViewerUrl}
              title="PDF Viewer"
              style={{ border: "none" }}
            ></iframe>
          ) : (
            <img
              id="modal-description"
              src={data.url}
              alt={`Expanded view of ${data.id}`}
              className={styles.modalImage}
              aria-label="Expanded image"
            />
          )}
          <section className={`${styles.modalBtn} ${isPdf ? styles.modalBtnPdf : ""}`}>
            <Stack spacing={2} direction="row">
              <a href={data.url} download className={styles.cancelBtn} aria-label="Download image">
                Download
              </a>
              <Button className={styles.deleteBtn} variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </Stack>
          </section>
        </section>
      </Modalui>
      <section className={styles.card}>
        <figure>
          {!isPdf ? (
            <img src={data.url} alt={data.id} className={styles.image} aria-label="Thumbnail image" />
          ) : (
            <img className={styles.pdfLogo} src={pdfLogo} alt="PDF logo" aria-label="PDF logo" />
          )}
          <figcaption>
            <div className={styles.btn}>
              <button
                aria-label="View details"
                className={styles.expandIcon}
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                <InfoOutlinedIcon className={styles.icon} />
              </button>
              <a href={data.url} download className={styles.expandIcon} aria-label="Download image">
                <FileDownloadOutlinedIcon className={styles.icon} />
              </a>
              <button className={styles.deleteIcon} onClick={handleDelete} aria-label="Delete">
                <DeleteOutlinedIcon className={styles.icon} />
              </button>
            </div>
          </figcaption>
        </figure>
      </section>
    </article>
  );
}
