import { useState, useEffect } from "react";
import styles from "./dashboard.module.scss";
import { Link } from "react-router-dom";
import WalletCard from "../../components/walletCard/WalletCard";
import RecentTrans from "../../components/recentTrans/RecentTrans";
// import BalGraph from "../../components/balGraph/BalGraph";   // No need to remove this
import WelcomeCard from "../../components/welcomeCard/WelcomeCard";
// import SearchBar from "src/components/UI/searchBar/SearchBar";
import { Avatar, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import {
  fetchDepositListAction,
  fetchTopWalletAction,
  fetchTransListAction,
  fetchWalletTransactionAction
} from "./dashboardSlice";
import { bgColorbyName } from "src/variable/randomColor";
import { dateTimeFormat, getTransactionValue } from "src/utils/utils";
import DepositCard from "src/components/depositCard/DepositeCard";

function Dashboard() {
  // const [searchWalletText, setSearchWalletText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const { userInfo, config, cognitoConfig } = useAppSelector((state) => state.auth);
  const { topWallets, transList, depositList } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  // const handleWalletSearch = (value: string) => {
  //   setSearchWalletText(value);
  // };

  useEffect(() => {
    const data = {
      from: 0,
      size: 5,
      walletFilter: {
        wallet_status: "ACTIVE"
      },
      orgFilter: {
        organisationDisplayId: cognitoConfig?.currentOrganisation?.organisation_id
      }
      // searchText: searchWalletText,
    };

    dispatch(fetchTopWalletAction(data));
  }, [dispatch, config?.currentOrganisation]);

  useEffect(() => {
    dispatch(
      fetchTransListAction({
        size: 5,
        from: 0,
        filter: {
          activityName: "NOVATTI_CARD_TRANSACTION"
        }
      })
    );
    dispatch(
      fetchDepositListAction({
        size: 5,
        from: 0,
        filter: {
          transactionType: "DEPOSIT"
        }
      })
    );
  }, [receiptPresent, isReviewed, dispatch]);

  useEffect(() => {
    const data = {
      organisationId: Number(config?.currentOrganisation?.id) || null
    };

    fetchWalletTransactionAction(data);
  }, [dispatch, config?.currentOrganisation]);

  return (
    <div className={styles.Dashboard} aria-label="Dashboard">
      <div className={styles.welcomeCard}>
        <WelcomeCard name={userInfo?.fullName} />
      </div>
      <div className={styles.topWallet}>
        {topWallets?.total > 0 && (
          <div className={styles.topWalletHead}>
            <div className={styles.topWalletHeadIn}>
              <div className={styles.topWalletHeadL}>
                <h1>Top Wallets</h1>
              </div>
              <div className={styles.topWalletHeadR}>
                <Link to="/wallets">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#1e293b",
                      height: "34px",
                      width: "82px",
                      fontSize: "13px",
                      fontWeight: "600",
                      lineHeight: "normal",
                      margin: "0",
                      padding: "0"
                    }}
                    aria-label="View all wallets"
                  >
                    View All
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.walletCards}>
              {topWallets.wallets.map((wallet) => (
                <Link to={`/wallets/${wallet.id}`} aria-label={`View wallet of ${wallet.participant.fullName}`}>
                  <WalletCard
                    key={wallet.id}
                    avatar={wallet.participant.fullName?.charAt(0).toLocaleUpperCase()}
                    title={wallet.participant.fullName}
                    subtitle={wallet.description}
                    walletBalance={wallet.balance}
                    cardBalance={wallet.cardBalance}
                    bg={bgColorbyName(wallet.participant.fullName)}
                  />
                </Link>
              ))}
            </div>
          </div>
        )}
        {(transList.trans.length > 0 || depositList.list.length > 0) && (
          <div className={styles.orgInsigts}>
            <h1>Organisation Insights</h1>
            <div className={styles.transCard}>
              {transList.trans.length > 0 && (
                <>
                  <div className={styles.transCardContainer}>
                    <h2>{transList.total}</h2>
                    <h3>Number of Transactions</h3>
                  </div>
                  <div className={styles.latesttransCardContainer}>
                    <div className={styles.head}>
                      <span className={styles.cardTitle}>Last Transaction</span>
                      <span className={styles.date}>{dateTimeFormat(transList?.trans[0]?.startDate)?.date}</span>
                    </div>
                    <div className={styles.card}>
                      <div className={styles.cardHolder}>
                        <div className={styles.cardHolderImg}>
                          <Avatar
                            id="av"
                            className={styles.av}
                            aria-label="Recipe"
                            style={{ backgroundColor: bgColorbyName(transList?.trans[0]?.name) }}
                          >
                            {transList?.trans[0]?.name.charAt(0).toLocaleUpperCase()}
                          </Avatar>
                        </div>
                        <div className={styles.cardHolderName}>
                          <h3>
                            {transList?.trans[0]?.data?.user?.attributes?.first_name +
                              " " +
                              transList?.trans[0]?.data?.user?.attributes?.last_name}
                          </h3>
                          <p>{transList?.trans[0]?.data?.wallet?.name}</p>
                        </div>
                      </div>
                      <div className={styles.cardBalance}>
                        <div className={styles.amount}>
                          <p className={styles.heading}>Amount:</p>
                          <p
                            className={styles.value}
                            style={{ color: getTransactionValue(transList?.trans[0])?.amountColor }}
                          >
                            {getTransactionValue(transList?.trans[0])?.amount}
                          </p>
                        </div>
                        <div className={styles.balance}>
                          <p className={styles.heading}>Balance:</p>
                          <p
                            className={styles.value}
                            style={{ color: transList?.trans[0]?.data?.wallet?.balance > 0 ? "green" : "red" }}
                          >
                            {transList?.trans[0]?.data?.wallet?.balance > 0
                              ? "$" + transList?.trans[0]?.data?.wallet?.balance
                              : "-$" + transList?.trans[0]?.data?.wallet?.balance}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {depositList?.list.length > 0 && (
                <div className={styles.latesttransCardContainer}>
                  <div className={styles.head}>
                    <span className={styles.cardTitle}>Last Deposit</span>
                    <span className={styles.date}>{dateTimeFormat(depositList?.list[0]?.startDate)?.date}</span>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.cardHolder}>
                      <div className={styles.cardHolderImg}>
                        <Avatar
                          id="av"
                          className={styles.av}
                          aria-label="Recipe"
                          style={{ backgroundColor: bgColorbyName(depositList?.list[0]?.name) }}
                        >
                          {depositList?.list[0]?.name.charAt(0).toLocaleUpperCase()}
                        </Avatar>
                      </div>
                      <div className={styles.cardHolderName}>
                        <h2>
                          {depositList?.list[0]?.data?.user?.attributes?.first_name +
                            " " +
                            depositList?.list[0]?.data?.user?.attributes?.last_name}
                        </h2>
                        <p>{depositList?.list[0]?.data?.wallet?.name}</p>
                      </div>
                    </div>
                    <div className={styles.cardBalance}>
                      <div className={styles.amount}>
                        <p className={styles.heading}>Amount:</p>
                        <p
                          className={styles.value}
                          style={{ color: getTransactionValue(depositList?.list[0])?.amountColor }}
                        >
                          {getTransactionValue(depositList?.list[0])?.amount}
                        </p>
                      </div>
                      <div className={styles.balance}>
                        <p className={styles.heading}>Balance:</p>
                        <p
                          className={styles.value}
                          style={{ color: depositList?.list[0]?.data?.wallet?.balance > 0 ? "green" : "red" }}
                        >
                          {depositList?.list[0]?.data?.wallet?.balance > 0
                            ? "$" + depositList?.list[0]?.data?.wallet?.balance
                            : "-$" + depositList?.list[0]?.data?.wallet?.balance}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles.DashTrans}>
        <RecentTrans
          transList={transList.trans}
          setReceiptPresent={setReceiptPresent}
          setIsReviewed={setIsReviewed}
          recentTransOver={styles.recentTransOver}
          subTitle={"These are the details of the transactions made"}
          page={"Dashboard"}
        />
        <DepositCard depositList={depositList?.list} recentTransOver={styles.recentTransOver} />
      </div>
    </div>
  );
}

export default Dashboard;
