import { useCallback, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import styles from "./transactionEditModal.module.scss";
// import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";
import { Avatar, CircularProgress } from "@mui/material";
import {
  Transaction,
  createNoteAction,
  deleteFile,
  getNoteAction,
  updateGstAmountAction,
  updateNoteAction
} from "src/page/transactions/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { bgColorbyName } from "src/variable/randomColor";
import {
  dateTimeFormat,
  getDecimalNumber,
  getSignedUrlFromS3,
  getTransactionValue,
  processFileName,
  uploadFileToS3
} from "src/utils/utils";
import { transactionPhoto } from "src/network/graphql/transactionService";
import PhotoCard, { AssetUrl } from "src/components/photoCard/PhotoCard";
import { ListAsset } from "src/types/commonTypes";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import config from "src/config/Config";
import { useDropzone, FileRejection } from "react-dropzone";
import { immediateToast } from "izitoast-react";
import { activityNameMapping } from "src/components/constant/constant";

interface Props {
  transaction: Transaction;
  handleClose: () => void;
}

export default function TransactionEditModal({ transaction, handleClose }: Props) {
  const dispatch = useAppDispatch();
  const { noteLoading, notes } = useAppSelector((state) => state.transaction);

  const [note, setNote] = useState<string>("");
  const [gst, setGst] = useState<string>("");
  const [photos, setPhotos] = useState<AssetUrl[]>([]);
  const [receipts, setReceipts] = useState<AssetUrl[]>([]);
  const [photosUpdated, setPhotosUpdated] = useState(false);

  const hiddenAddReceipt = useRef<HTMLInputElement>(null);
  const hiddenAddPhoto = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(getNoteAction(transaction?.id));
    setGst(String(transaction?.data?.price_components?.gst));
  }, [dispatch, transaction?.id]);

  useEffect(() => {
    if (notes) {
      setNote(notes[0]?.message || "");
    }
  }, [notes]);

  const handleSaveNotes = () => {
    if (notes.length > 0 && notes[0]?.noteId) {
      dispatch(
        updateNoteAction(notes[0].noteId, note, () => {
          dispatch(getNoteAction(transaction?.id));
        })
      );
    } else {
      dispatch(
        createNoteAction(transaction?.id, note, () => {
          dispatch(getNoteAction(transaction?.id));
        })
      );
    }

    if (transaction?.data?.price_components?.gst !== Number(gst)) {
      dispatch(updateGstAmountAction(transaction?.id, Number(gst)));
    }
  };

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const photoResponse = await transactionPhoto("PHOTO", transaction?.id);
        const receiptResponse = await transactionPhoto("RECEIPT", transaction?.id);

        const photoAssets: ListAsset[] = photoResponse.data.list_assets.asset.filter(
          (asset: ListAsset) => asset.asset_status === "ACTIVE"
        );
        const receiptAssets: ListAsset[] = receiptResponse.data.list_assets.asset.filter(
          (asset: ListAsset) => asset.asset_status === "ACTIVE"
        );

        const photoUrls = await Promise.all(
          photoAssets.map((photo: ListAsset) => getSignedUrlFromS3(photo.attributes.bucket_name, photo.attributes.key))
        );

        const receiptUrls = await Promise.all(
          receiptAssets.map((receipt: ListAsset) =>
            getSignedUrlFromS3(receipt.attributes.bucket_name, receipt.attributes.key)
          )
        );

        setPhotos(
          photoAssets.map((photo: ListAsset, index: number) => ({
            id: photo.attributes.key,
            url: photoUrls[index],
            assetId: photo.asset_id
          }))
        );
        setReceipts(
          receiptAssets.map((receipt: ListAsset, index: number) => ({
            id: receipt.attributes.key,
            url: receiptUrls[index],
            assetId: receipt.asset_id
          }))
        );
      } catch (error) {
        console.error("Error fetching assets:", error);
      }

      setPhotosUpdated(false);
    };

    fetchAssets();
  }, [photosUpdated, transaction?.id]);

  const handleFileUpload = async (file: File, type: "PHOTO" | "RECEIPT") => {
    const data = {
      file: file,
      fileName: processFileName(file.name, transaction?.id),
      S3Bucket: config?.S3_ASSET_BUCKET,
      MetaData: {
        object_id: transaction?.id,
        object_type: "TRANSACTION",
        asset_type: type
      }
    };
    await uploadFileToS3(data);
    setPhotosUpdated(true);
  };

  const handlePhotoDelete = async (assetId: string) => {
    dispatch(
      deleteFile(assetId, () => {
        setPhotosUpdated(true);
      })
    );
  };

  const onDropReceipt = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      immediateToast("error", {
        message: `The format is not supported for receipts.`,
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }
    acceptedFiles.forEach((file: File) => {
      handleFileUpload(file, "RECEIPT");
    });
  }, []);

  const onDropPhoto = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      immediateToast("error", {
        message: `The format is not supported for photos.`,
        timeout: 3000,
        position: "topCenter"
      });
      return;
    }
    acceptedFiles.forEach((file: File) => {
      handleFileUpload(file, "PHOTO");
    });
  }, []);

  const { getRootProps: getRootPropsReceipt, isDragActive: isDragActiveReceipt } = useDropzone({
    onDrop: onDropReceipt,
    noClick: true,
    accept: {
      "image/*": [],
      "application/pdf": [".pdf"]
    }
  });

  const { getRootProps: getRootPropsPhoto, isDragActive: isDragActivePhoto } = useDropzone({
    onDrop: onDropPhoto,
    noClick: true,
    accept: {
      "image/*": [],
      "application/pdf": [".pdf"]
    }
  });

  const cutOffDate = "2024-03-13T00:00:00Z";
  const rednerBalance = () => {
    if (transaction?.endDate > cutOffDate) {
      if (transaction?.data?.wallet?.balance) {
        if (transaction?.data?.wallet?.balance > 0) {
          return "$" + getDecimalNumber(transaction?.data?.wallet?.balance);
        } else {
          return "-$" + getDecimalNumber(transaction?.data?.wallet?.balance);
        }
      } else {
        return "$0.00";
      }
    } else {
      return "---";
    }
  };

  return (
    <div className={styles.referModalBox}>
      <header className={styles.referModalTop}>
        <div className={styles.topL}>
          <p>
            Transaction Id: {transaction?.id}
            <br />
            <span>{dateTimeFormat(transaction?.endDate)?.datetime}</span>
          </p>
        </div>
        <div className={styles.topR}>
          <Button className={styles.closeBtn} variant="contained" onClick={handleClose} aria-label="Close">
            Close
          </Button>
        </div>
      </header>
      {noteLoading ? (
        <div className={styles.progress}>
          <CircularProgress sx={{ color: "#0f172a" }} />
        </div>
      ) : (
        <>
          <main className={styles.referModalBottom} id={styles.scrollBar}>
            <form id={styles.scrollBarH}>
              <div className={styles.bottomI}>
                <div className={styles.Amount}>
                  <span className={styles.title}>Amount</span>
                  <span className={styles.input} style={{ color: getTransactionValue(transaction)?.amountColor }}>
                    {getTransactionValue(transaction)?.amount}
                  </span>
                </div>
                <div className={styles.Merchant}>
                  <span className={styles.title}>Merchant</span>
                  <span className={styles.input}>{getTransactionValue(transaction)?.value}</span>
                </div>
              </div>
              <div className={styles.bottomII}>
                <div className={styles.Wallet}>
                  <span className={styles.title}>Wallet</span>
                  <div className={styles.input}>
                    <div className={styles.inputL}>
                      <Avatar
                        id="av"
                        aria-label={`Wallet ${transaction?.data?.wallet?.name}`}
                        style={{ backgroundColor: bgColorbyName(transaction?.data?.wallet?.name) }}
                      >
                        {transaction?.data?.wallet?.name?.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className={styles.inputR}>
                      <span className={styles.name}>{transaction?.data?.wallet?.name}</span>
                      <span className={styles.walletName} style={{ color: "#64748B" }}>
                        {transaction?.data?.wallet?.display_id}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.Category}>
                  <span className={styles.title}>Balance</span>
                  <div className={styles.input}>
                    <span
                      style={{
                        color:
                          transaction?.endDate > cutOffDate && transaction?.data?.wallet?.balance > 0 ? "green" : "red"
                      }}
                    >
                      {rednerBalance()}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.bottomIII}>
                <div className={styles.purchasedBy}>
                  <span className={styles.title}>Purchase made by</span>
                  <div className={styles.input}>
                    <div className={styles.inputL}>
                      <Avatar
                        id="av"
                        aria-label="Recipe"
                        style={{
                          backgroundColor: bgColorbyName(
                            transaction?.data?.transaction_user
                              ? transaction?.data?.transaction_user?.attributes?.first_name
                              : transaction?.data?.user?.attributes?.first_name
                          )
                        }}
                      >
                        {transaction?.data?.transaction_user
                          ? transaction?.data?.transaction_user?.attributes?.first_name?.charAt(0).toLocaleUpperCase()
                          : transaction?.data?.user?.attributes?.first_name?.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className={styles.inputR}>
                      <span className={styles.name}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {transaction?.data?.transaction_user
                          ? transaction?.data?.transaction_user?.attributes?.first_name +
                            " " +
                            transaction?.data?.transaction_user?.attributes?.last_name
                          : transaction?.data?.user
                            ? transaction?.data?.user?.attributes?.first_name +
                              " " +
                              transaction?.data?.user?.attributes?.last_name
                            : "---"}
                      </span>
                      <span className={styles.email} style={{ color: "#64748B" }}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {transaction?.data?.transaction_user
                          ? transaction?.data?.transaction_user?.email
                          : transaction?.data?.user
                            ? transaction?.data?.user?.email
                            : "---"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.ActivityName}>
                  <span className={styles.title}>Activity Name</span>
                  <span className={styles.input}>{activityNameMapping[transaction?.name]}</span>
                </div>
              </div>
              <div className={styles.bottomIV}>
                <div className={styles.gst}>
                  <span className={styles.title}>GST Amount</span>
                  <input
                    type="number"
                    placeholder="Enter GST amount"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                  />
                </div>
                {transaction?.name === "NOVATTI_CARD_TRANSACTION" && (
                  <div className={styles.cardUsed}>
                    <span className={styles.title}>Card Used</span>
                    <span className={styles.input}>{transaction?.data?.card?.holder_name}</span>
                    <span className={styles.input} style={{ color: "#64748B" }}>
                      {transaction?.data?.ppan ? "************" + transaction?.data?.ppan.slice(-4) : "----"}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.dropNdrag}>
                <div className={styles.bottomIV}>
                  <div className={styles.receipt}>
                    <div className={styles.title}>Receipt</div>
                    <Button
                      variant="contained"
                      className={styles.addReceipt}
                      onClick={() => {
                        if (hiddenAddReceipt.current) {
                          hiddenAddReceipt.current.click();
                        }
                      }}
                    >
                      <input
                        id="hiddenAddReceiptInput"
                        type="file"
                        accept="image/*,application/pdf"
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            handleFileUpload(e.target.files[0], "RECEIPT");
                          }
                        }}
                        ref={hiddenAddReceipt}
                        style={{ display: "none" }}
                        aria-label="Add Receipt"
                      />
                      <div className={styles.logo}>
                        <TextSnippetOutlinedIcon />
                      </div>
                      <div className={styles.text}>Add Receipt</div>
                    </Button>
                    <div className={styles.filepondWrapper} {...getRootPropsReceipt()}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {isDragActiveReceipt || receipts.length === 0 ? (
                        <div className={styles.noData}>
                          Drag & Drop
                          <br />
                          Add receipt
                        </div>
                      ) : (
                        receipts.map((receipt) => (
                          <PhotoCard key={receipt.id} data={receipt} onDelete={handlePhotoDelete} />
                        ))
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.bottomV}>
                  <div className={styles.photo}>
                    <div className={styles.title}>Photos</div>
                    <Button
                      variant="contained"
                      className={styles.addPhoto}
                      onClick={() => {
                        if (hiddenAddPhoto.current) {
                          hiddenAddPhoto.current.click();
                        }
                      }}
                    >
                      <div className={styles.logo}>
                        <CameraAltOutlinedIcon />
                      </div>
                      <div className={styles.text}>Add Photo</div>
                    </Button>
                    <input
                      id="hiddenAddPhotoInput"
                      type="file"
                      accept="image/*,application/pdf"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          handleFileUpload(e.target.files[0], "PHOTO");
                        }
                      }}
                      ref={hiddenAddPhoto}
                      aria-label="Add Photo"
                      style={{ display: "none" }}
                    />
                    <div className={styles.filepondWrapper} {...getRootPropsPhoto()}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {isDragActivePhoto || photos.length === 0 ? (
                        <div className={styles.noData}>
                          Drag & Drop
                          <br />
                          Add photo
                        </div>
                      ) : (
                        photos.map((photo) => <PhotoCard key={photo.id} data={photo} onDelete={handlePhotoDelete} />)
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.bottomVI}>
                <span className={styles.title}>Notes</span>
                <textarea
                  className={styles.input}
                  placeholder="Type your comments..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </form>
          </main>

          <footer className={styles.footer}>
            <div className={styles.saveBtn}>
              <Button variant="contained" className={styles.requestBtn} onClick={handleSaveNotes}>
                Save
              </Button>
            </div>
          </footer>
        </>
      )}
    </div>
  );
}
