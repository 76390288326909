import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./attachUser.module.scss";
import CloseIcon from "@mui/icons-material/Close";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { reverseRoleMapping, roleMapping } from "src/components/constant/constant";
import { useAppSelector, useAppDispatch } from "src/page/store";
import { getUserByEmailAction } from "src/page/users/usersSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface AddUserModalProps {
  handleClose: () => void;
  onConnectUser: (_userId: string | number, _role: string, _close: boolean) => void;
}
export default function AttachUser({ handleClose, onConnectUser }: AddUserModalProps) {
  const { cognitoConfig } = useAppSelector((state) => state.auth);
  const { userData, userDataLoading } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [emailId, setEmailId] = useState("");
  const [userId, setUserId] = useState(0);
  const [role, setRole] = useState<string | null>(null);
  const [error, setError] = useState({ email: "", role: "", successfull: "" });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

  const handleCancelClose = () => {
    handleClose();
  };

  const handleCancel = () => {
    if (!emailId.trim() || !role?.trim()) {
      setError({
        ...error,
        email: emailId ? "" : "Email is required",
        role: role ? "" : "Role is required"
      });
      return;
    }

    onConnectUser(userId, role, true);
  };

  const handleAddUser = () => {
    if (!emailId.trim() || !role?.trim()) {
      setError({
        ...error,
        email: emailId ? "" : "Email is required",
        role: role ? "" : "Role is required"
      });
      return;
    }

    onConnectUser(userId, role, false);
    setEmailId("");
    setRole(null);

    setError({
      email: "",
      role: "",
      successfull: ""
    });
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
    setRole(value ? reverseRoleMapping[value] : "Participant");
  };

  const handleFetchUser = () => {
    if (!emailRegex.test(emailId)) {
      setError({ ...error, email: "Invalid Email", successfull: "" });
      return;
    }
    dispatch(
      getUserByEmailAction(
        emailId,
        () => {
          setError({
            ...error,
            email: "",
            successfull: "Email is Valid"
          });
        },
        () => {
          setError({
            ...error,
            email: "Email is not registered",
            successfull: ""
          });
        }
      )
    );
  };

  useEffect(() => {
    if (userData.user.id) {
      setUserId(userData.user.id);
    }
  }, [userData.user.id]);

  return (
    <div className={styles.referModalBox}>
      <main className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect User</h5>
            <button onClick={handleCancelClose} aria-label="close button">
              <CloseIcon onClick={handleCancelClose} />
            </button>
          </div>

          <div className={styles.email}>
            <label htmlFor="email">User Email</label>
            <div className={styles.userEmail}>
              <input
                id="email"
                type="email"
                placeholder="example@gmail.com"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
              {userDataLoading ? (
                <CircularProgress className={styles.loader} />
              ) : (
                <Button className={styles.getUser} variant="contained" onClick={handleFetchUser} aria-label="get user">
                  Get User
                </Button>
              )}
            </div>
            {error.email && (
              <div className={styles.error} role="alert">
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.email}
              </div>
            )}
            {error.successfull && (
              <div className={styles.success} role="status">
                <CheckCircleOutlineIcon className={styles.successIcon} />
                {error.successfull}
              </div>
            )}
          </div>
          <div className={styles.Role}>
            <label htmlFor="role">Role</label>
            <Autocomplete
              id="role"
              className={styles.autoComplete}
              options={cognitoConfig?.rolesAllowedToAdd?.map((roles: any) => roleMapping[roles]) || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} placeholder="Select your Role.." />}
              value={role}
              onChange={handleParticipantChange}
            />
            {error.role && (
              <div className={styles.error} role="alert">
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.role}
              </div>
            )}
          </div>
        </form>
      </main>
      <footer className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleCancel} aria-label="secondary button">
              Add & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddUser} aria-label="primary button">
              Add & another User
            </Button>
          </Stack>
        </div>
      </footer>
    </div>
  );
}
