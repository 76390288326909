import { Gender, Initial, kycStatusType, PaginationRequest } from "src/types/commonTypes";
import client from "../client";
import publicClient from "../publicClient";
import internalClient from "../internalClient";

export type CreateUserRequest = {
  firstName: string;
  lastName: string;
  gender: Gender;
  initial: Initial;
  email: string;
  phone: string;
  role: string;
  dob: string;
  city: string;
  country: string;
  line1: string;
  line2: string;
  postcode: string;
  state: string;
  organisationId?: string;
  userName?: string;
};

export const createUser = (data: CreateUserRequest) => {
  return client({
    query: `
      mutation createUser ($input: CreateUserInput) {
        create_user(input: $input) {
          user_id
        }
      }
    `,
    variables: {
      input: {
        address: {
          city: data.city,
          country: data.country,
          line_1: data.line1,
          line_2: data.line2,
          post_code: data.postcode,
          state: data.state
        },
        date_of_birth: data.dob,
        email: data.email.toLowerCase(),
        first_name: data.firstName,
        gender: data.gender,
        initial: data.initial,
        last_name: data.lastName,
        phone: data.phone,
        username: data.userName,
        organisation_id: data.organisationId,
        role_name: data.role
      }
    }
  });
};

export type SearchUserRequest = {
  user_filter?: {
    is_active?: boolean | null;
    email?: string | null;
    full_name?: string | null;
    organisation_id?: number | string | null;
    organisationDisplayId?: string;
    role?: string;
  };
  searchCriteria?: string;
  size?: number | null;
  from?: number | null;
};

export const searchUser = (data: SearchUserRequest) => {
  return client({
    query: `query SearchUser($input: SearchInput) {
      search_user(input: $input){
        total_pages
        total_size
        user_listing {
          attributes {
            first_name
            last_name
          }
          current_organisation_role
          created_at
          display_id
          email
          is_active
          phone
          user_id
          username
          roles {
            value
            organisation_id
          }
        }
      }
    }`,
    variables: {
      input: {
        filter: {
          user_filter: data.user_filter
        },
        search_criteria: data.searchCriteria,
        size: data.size,
        from: data.from
      }
    }
  });
};
export const searchInternalUser = (data: SearchUserRequest) => {
  return internalClient({
    query: `query SearchUser($input: SearchInput) {
      search_user(input: $input){
        total_pages
        total_size
        user_listing {
          attributes {
            first_name
            last_name
          }
          current_organisation_role
          created_at
          display_id
          email
          is_active
          phone
          user_id
          username
          roles {
            value
            organisation_id
          }
        }
      }
    }`,
    variables: {
      input: {
        filter: {
          user_filter: data.user_filter
        },
        search_criteria: data.searchCriteria,
        size: data.size,
        from: data.from
      }
    }
  });
};

export const userOrganisation = (userId: string) => {
  return internalClient({
    query: `query UserOrganisation($input: UserOrganisationsInput) {
      get_user_organisations(input: $input){
        organisation_id
        name
        display_id
        role
      }
    }`,
    variables: {
      input: {
        user_id: userId
      }
    }
  });
};

export const getUserById = (id: string) => {
  return client({
    query: `
    query GetUserById($input: GetUserInput) {
      get_user(input: $input) {
        user_id
        username
        cognito_username
        email
        guardian_id
        is_active
        display_id
        created_at
        attributes {
          address {
            city
            country
            post_code
            line_1
            line_2
            state
          }
          first_name
          last_name
          gender
          initial
          date_of_birth
          profile_image
        }
        security_status
        phone
        roles {
          value
          organisation_id
        }
      }    
    }`,
    variables: {
      input: {
        user_id: id
      }
    }
  });
};

export type UpdateUserRequest = {
  id?: number | string;
  organisationId?: string;
  active?: boolean;
  email?: string;
  userName?: string;
  initial?: Initial | null;
  firstName?: string;
  lastName?: string;
  dob?: string;
  phoneNumber?: string;
  gender?: Gender | null;
  role?: string;
  state?: string;
  city?: string;
  line1?: string;
  line2?: string;
  zipcode?: string;
  country?: string;
  accessCode?: string;
  fullName?: string;
  guardianId?: number | string;
  kycStatus?: kycStatusType | null;
  profileImage?: string;
};

export const updateUser = (data: UpdateUserRequest) => {
  return client({
    query: `mutation UpdateUser($input: UpdateUserInput) {
        update_user(input: $input) {
            user_id
        }
    }`,
    variables: {
      input: {
        organisation_id: data.organisationId,
        user_id: data.id,
        email: data.email,
        initial: data.initial,
        first_name: data.firstName,
        last_name: data.lastName,
        username: data.userName,
        gender: data.gender,
        phone: data.phoneNumber,
        // // profile_image: data.profileImage,
        date_of_birth: data.dob,
        address: {
          line_1: data.line1,
          line_2: data.line2,
          city: data.city,
          state: data.state,
          post_code: data.zipcode,
          country: data.country
        }
        // guardian_id: data.guardianId
      }
    }
  });
};

export const getUserWallets = (userId: string) => {
  return client({
    query: `
      query GetUserWallets($input: UserQueryInput) {
        get_user_wallets(input: $input) {
          balance
          display_id 
          description 
          name 
          wallet_id 
          wallet_type
          user {
            email
            user_id
            attributes {
              last_name
              first_name
            }
          }
        }
      }
    `,
    variables: {
      input: {
        user_id: userId
      }
    }
  });
};

export type ConnectUserRequest = {
  organisationId: string;
  roleName: string;
  userId: string;
};

export const connectUser = (data: ConnectUserRequest) => {
  return client({
    query: `
    mutation ConnectUserToOrganisation($input: ConnectUserToOrganisationInput) {
      connect_user_to_organisation(input: $input) {
        card_id
        created_at
        organisation_id
        relationship_id
        status
        sub_type
        type
        user_id
        value
        wallet_id
      }
    }`,
    variables: {
      input: {
        organisation_id: data.organisationId,
        role_name: data.roleName,
        user_id: data.userId
      }
    }
  });
};

export type UpdateUserRoleRequest = {
  organisationId: string;
  roleName: string;
  userId: string;
};

export const updateUserRole = (data: UpdateUserRoleRequest) => {
  return client({
    query: `
    mutation UpdateUserRole($input: UpdateUserRoleInput) {
      update_user_role(input: $input) {
        organisation_id
        relationship_id
        user_id
        value
        type
        sub_type
        status
      }
    }`,
    variables: {
      input: {
        organisation_id: data.organisationId,
        role_name: data.roleName,
        user_id: data.userId
      }
    }
  });
};

export type SuspendUserRequest = {
  organisationId: string;
  userId: string;
};

export const suspendUser = (data: SuspendUserRequest) => {
  return client({
    query: `
    mutation SuspendUser($input: SuspendUserInput) {
      suspend_user(input: $input) {
        card_id
        created_at
        relationship_id
        organisation_id
        status
        sub_type
        type
        user_id
        value
        wallet_id
      }
    }`,
    variables: {
      input: {
        organisation_id: data.organisationId,
        user_id: data.userId
      }
    }
  });
};

export const getUserByEmail = (email: string) => {
  return client({
    query: `
    query GetUser($input: GetUserInput) {
      get_user(input: $input) {
        display_id
        email
        is_active
        security_status
        user_id
        username
        phone
        roles {
          card_id
          created_at
          organisation_id
          relationship_id
          sub_type
          status
          type
          user_id
          value
          wallet_id
        }
        attributes {
          address {
            city
            country
            post_code
            line_1
            line_2
            state
          }
          first_name
          last_name
          gender
          initial
          date_of_birth
        }
      }
    }`,
    variables: {
      input: {
        email: email
      }
    }
  });
};

export const getUserCard = (userId: string, organisationId: string) => {
  return client({
    query: `query GetUserCards($input: GetUserCardsInput) { 
      get_user_cards(input: $input) { 
        cards {
          ppan
          card_type
          card_status
          card_id
          holder_name
          user {
            email
            attributes {
              first_name
              last_name
            }
          }
          card_setting {
            card_id
            ppan
            wallet_id
            card_setting_status
            card_setting_id
            expires_at
          }
        }
      } 
    }`,
    variables: {
      input: {
        user_id: userId,
        organisation_id: organisationId
      }
    }
  });
};

export const checkUserNameAvailability = (userName: string) => {
  return publicClient({
    query: `
      query IsUserNameAvailable($username: String!) {
        is_username_available(username: $username)
      } 
    `,
    variables: {
      username: userName
    }
  });
};

export const checkEmailAvailability = (email: string) => {
  return publicClient({
    query: `
      query IsEmailAvailable($email: String!) {
        is_email_available(email: $email)
      } 
    `,
    variables: {
      email
    }
  });
};

export type UpdateKycRequest = {
  userId: string;
  kycStatus: kycStatusType;
};

export const updateKYC = (data: UpdateKycRequest) => {
  return client({
    query: `
    mutation MyMutation($input: UpdateSecurityStatusInput) {
      update_security_status(input: $input) {
        security_id
      }
    }
    `,
    variables: {
      input: {
        object_id: data.userId,
        object_type: "USER",
        security_status: data.kycStatus,
        security_type: "FRANKIE"
      }
    }
  });
};

export const getCardSetting = (userId: string) => {
  return client({
    query: `query GetCardSettings($input: GetCardSettingsInput) {
      get_card_settings(input: $input) { 
        card_setting_id
	      card_setting_status
	      status
	      ppan
	      card_id
	      wallet_id
	      expires_at
 	      sub_type
	      user_id 
        updated_at
      } 
    }`,
    variables: {
      input: {
        user_id: userId
      }
    }
  });
};

export interface SearchCardSetting extends PaginationRequest {
  cardSettingFilter?: {
    card_id?: string;
    wallet_id?: string;
    status?: string;
    organisation_id?: string;
  };
}

export const searchCardSetting = (data: SearchCardSetting) => {
  return client({
    query: `query SearchCardSetting($input: SearchInput) {
      search_card_settings(input: $input) { 
        items {
          card_setting_id
	        card_setting_status
	        status
	        ppan
	        card_id
	        wallet_id
	        expires_at
 	        sub_type
	        user_id 
          updated_at
        }
	      total_pages
	      total_size
      } 
    }`,
    variables: {
      input: {
        filter: {
          card_setting_filter: data.cardSettingFilter
        },
        size: data.size,
        from: data.from
      }
    }
  });
};

export const deleteCardSetting = (cardId: string, walletId: string) => {
  return client({
    query: `
    mutation RemoveCardSetting($input: RemoveCardSettingInput) {
      remove_card_setting(input: $input) {
        code
        message
      }
    }`,
    variables: {
      input: {
        card_id: cardId,
        wallet_id: walletId
      }
    }
  });
};
