import { useState, ChangeEvent } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./addWalletModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { fetchUserListAction } from "src/page/wallets/walletSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { PARTICIPANT } from "src/components/constant/constant";
import { User } from "src/types/commonTypes";
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";

export type AddWalletIn = {
  walletName: string;
  description: string;
  participantId: string;
  walletType: string;
  defaultWallet?: boolean;
};
interface AddUserModalProps {
  handleClose: () => void;
  isWalletListEmpty: boolean;
  onAddWallet: (_addWallet: AddWalletIn, _close: boolean) => void;
  rootContainer?: string;
  closeButton?: boolean;
  secondaryButton?: boolean;
  heading?: string;
  buttonText?: {
    primaryButton?: string;
    secondaryButton?: string;
  };
  userId?: string;
  walletloading?: boolean;
}

export default function AddUserModal({
  handleClose,
  onAddWallet,
  rootContainer,
  closeButton = true,
  secondaryButton = true,
  heading = "Create Wallet",
  buttonText,
  userId,
  walletloading = false
}: AddUserModalProps) {
  const { users, eWalletLoading } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const [walletName, setWalletName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [participant, setParticipant] = useState<User | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [error, setError] = useState({
    description: "",
    type: "",
    wallet: ""
  });

  const handleCancel = () => {
    handleClose();
  };

  const fetchOption = debounce((inputValue: string) => {
    if (!inputValue && inputValue?.length < 2) return [];

    const data = {
      user_filter: {
        full_name: inputValue,
        role: PARTICIPANT
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleInputChange = (event: ChangeEvent<object>, newInputValue: string) => {
    fetchOption(newInputValue);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWalletName(event.target.value);
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: User | null) => {
    setParticipant(value || null);
  };

  const handleSave = () => {
    if (!description.trim() || !walletName.trim() || !type?.trim()) {
      setError({
        description: description ? "" : "Description is required",
        type: type ? "" : "type is required",
        wallet: walletName ? "" : "Wallet name is required"
      });
      return;
    }

    const walletData = {
      participantId: participant?.id ?? "",
      walletName: walletName,
      description: description,
      walletType: type
    };

    onAddWallet(walletData, true);
  };

  // const getProgressLabel = () => {
  //   switch (eWalletCreationProgress.step) {
  //     case "creating_wallet":
  //       return "Creating your wallet";
  //     case "setting_preferences":
  //       return "Setting up wallet preferences";
  //     case "ordering_card":
  //       return "Ordering your digital card";
  //     case "waiting_for_card":
  //       return "Hold tight, it's taking longer than usual";
  //     case "completed":
  //       return "E-wallet creation completed";
  //     default:
  //       return "";
  //   }
  // };

  return (
    <div className={`${styles.referModalBox} ${rootContainer}`} aria-live="polite">
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>{heading}</h5>
            {closeButton && (
              <button onClick={handleCancel} aria-label="Close">
                <CloseIcon onClick={handleCancel} />
              </button>
            )}
          </div>
          {walletloading || eWalletLoading ? (
            <>
              {walletloading && (
                <div className={styles.progress}>
                  <CircularProgress sx={{ color: "#0f172a" }} />
                </div>
              )}
              {/* {eWalletLoading && (
                <div>
                  <LinearProgress variant="determinate" value={eWalletCreationProgress.progress} />
                  <Typography>{getProgressLabel()}</Typography>
                </div>
              )} */}
            </>
          ) : (
            <>
              <div className={styles.text}>
                <label>Wallet Name</label>
                <input
                  type="text"
                  id="walletname"
                  placeholder="Enter wallet name here"
                  value={walletName}
                  onChange={handleWalletChange}
                  aria-label="Wallet Name"
                  required
                />
                {error.wallet && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.wallet}
                  </div>
                )}
              </div>
              <div className={styles.text}>
                <label>Description</label>
                <input
                  type="text"
                  id="description"
                  placeholder="i.e. use this wallet for 
                  coffee"
                  value={description}
                  aria-label="Description"
                  onChange={handleDescriptionChange}
                  required
                />
                {error.description && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.description}
                  </div>
                )}
              </div>
              {!userId?.trim() && (
                <div className={styles.participant}>
                  <label>Participant</label>
                  <Autocomplete
                    className={styles.autoComplete}
                    options={users.list}
                    getOptionLabel={(option: any) => option?.firstName ?? option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Andy Baker" aria-label="Participant" />
                    )}
                    value={participant}
                    onChange={handleParticipantChange}
                    onInputChange={handleInputChange}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <span>{`${option.firstName} ${option.lastName}`}</span>
                        <span style={{ color: "#64748B" }}>{option.email}</span>
                      </li>
                    )}
                  />
                  {/* {error.participant && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.participant}
              </div>
            )} */}
                </div>
              )}
              <div className={styles.text}>
                <label>Wallet Type</label>
                <FormControl>
                  <Select
                    value={type || ""}
                    onChange={(e) => {
                      if (e.target.value.trim()) {
                        setError({ ...error, type: "" });
                      }
                      setType(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      color: {
                        GENERAL: "",
                        DIGITAL: "",
                        CASJ: "",
                        "": "grey",
                        default: "grey"
                      }[type ?? "default"]
                    }}
                  >
                    <MenuItem value="" sx={{ color: "grey" }}>
                      Select type
                    </MenuItem>
                    <MenuItem value="GENERAL">General</MenuItem>
                    {/* <MenuItem value="DIGITAL">Digital</MenuItem> */}
                    <MenuItem value="CASH">Cash</MenuItem>
                  </Select>
                  {error.type && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.type}
                    </div>
                  )}
                </FormControl>
              </div>
            </>
          )}
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            {secondaryButton && (
              <Button className={styles.cancelBtn} variant="outlined" onClick={handleCancel}>
                {buttonText?.secondaryButton || "Cancel"}
              </Button>
            )}
            <Button
              className={styles.sendBtn}
              variant="contained"
              onClick={handleSave}
              disabled={eWalletLoading || walletloading}
            >
              {eWalletLoading || walletloading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                buttonText?.primaryButton || "Save"
              )}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
