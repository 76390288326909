import { Autocomplete, Button, CircularProgress, TextField } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styles from "./internalPayment.module.scss";
import { Wallet } from "src/page/wallets/walletSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  // fetchAllWalletListAction,
  fetchUserListAction,
  fetchWalletListAction,
  internalPayAction,
  resetAllWalletAction,
  resetwalletListAction
} from "src/page/newPayment/newPaymentSlice";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import Modalui from "../UI/modal/Modalui";
import { Users } from "src/page/users/usersSlice";

interface InternalPaymentProp {
  onClose?: () => void;
}

export default function InternalPayment({ onClose }: InternalPaymentProp) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  // const { userInfo } = useAppSelector((state) => state.auth);
  const { wallets, users, internalPayLoading } = useAppSelector((state) => state.newPayment);
  const [sourceParticipant, setSourceParticipant] = useState<Users | null>(null);
  const [designationParticipant, setDesignationParticipant] = useState<Users | null>(null);
  const [sourceWallet, setSourceWallet] = useState<Wallet | null>(null);
  const [designationWallet, setDesignationWallet] = useState<Wallet | null>(null);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState({
    sourceWallet: "",
    designationWallet: "",
    amount: ""
  });

  const handleClose = () => setOpen(false);

  const validateAmount = () => {
    const newError = { ...error };
    const numericAmount = Number(amount);

    if (numericAmount <= 0) {
      newError.amount = "Amount can't be less than or equal to 0";
    } else if (sourceWallet && numericAmount > sourceWallet.balance) {
      newError.amount = `Amount cannot be more than $${sourceWallet.balance}`;
    } else if (numericAmount > 1000) {
      newError.amount = "Amount cannot be more than $1000";
    } else {
      newError.amount = "";
    }

    setError(newError);
    return newError.amount === "";
  };

  console.log("users", users);

  const fetchUserList = useCallback(() => {
    dispatch(fetchUserListAction({ size: 20, from: 0 }));
  }, [dispatch]);

  useEffect(() => {
    fetchUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWalletList = useCallback(
    (userId: string) => {
      dispatch(fetchWalletListAction(userId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (amount) {
      validateAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, sourceWallet]);

  const handleBack = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleTransferFund = () => {
    const data = {
      amount: Number(amount),
      designationWalletId: String(designationWallet?.id),
      sourceWalletId: String(sourceWallet?.id)
    };

    dispatch(
      internalPayAction(data, () => {
        setSourceWallet(null);
        setDesignationWallet(null);
        setAmount("");
        setOpen(false);
        dispatch(resetAllWalletAction([]));
      })
    );
  };

  const handleConfirm = () => {
    if (!sourceWallet?.id || !designationWallet?.id || !amount.trim()) {
      setError({
        sourceWallet: sourceWallet?.id ? "" : "Source Wallet is required",
        // eslint-disable-next-line no-nested-ternary
        designationWallet: designationWallet?.id
          ? sourceWallet?.id === designationWallet?.id
            ? "From wallet and To wallet must be different"
            : ""
          : "Designation Wallet is required",
        amount: amount.trim() ? "" : "Amount is required"
      });
      return;
    }
    if (validateAmount()) {
      setOpen(true);
    }
  };

  const handleUserChange = debounce((event: React.ChangeEvent<object>, input: string) => {
    // fetchUserList();
    if (input.length < 2) return;
    const data = {
      user_filter: {
        full_name: input
      },
      size: 15,
      from: 0
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  return (
    <>
      <Modalui open={open} handleClose={handleClose}>
        <div className={styles.referModalBox}>
          <div className={styles.referModalTop}>
            <form>
              <div className={styles.setting}>
                <span>{`Are you sure you want to transfer`}</span>
                <span>{`$${amount} from ${sourceWallet?.name} to ${designationWallet?.name}`}</span>
              </div>
            </form>
          </div>
          <div className={styles.referModalBottom}>
            {internalPayLoading ? (
              <div className={styles.progress}>
                <CircularProgress sx={{ color: "#0f172a" }} />
              </div>
            ) : (
              <div className={styles.referModalBtn}>
                <div className={styles.btnL}>
                  <Button className={styles.reportBtn} variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
                <div className={styles.btnR}>
                  <Button className={styles.sendBtn} variant="contained" onClick={handleTransferFund}>
                    Sure
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modalui>
      <div className={styles.internalPaymentCard} aria-label="Transfer Funds">
        <h1>Transfer Funds</h1>
        <div className={styles.fromWallet}>
          <h3>From Wallet</h3>
          <Autocomplete
            options={users?.list}
            getOptionLabel={(option) => option?.firstName}
            filterOptions={(x) => x}
            value={sourceParticipant}
            onInputChange={handleUserChange}
            onChange={(event, newValue) => {
              setSourceParticipant(newValue);
              setSourceWallet(null);
              setDesignationParticipant(null);
              setDesignationWallet(null);
              dispatch(resetwalletListAction({ list: [] }));
              fetchWalletList(String(newValue?.userId));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Participant" placeholder="type to search...." />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <span>{option?.firstName + " " + option?.lastName}</span>
                <span style={{ color: "#64748B" }}>{option?.email}</span>
              </li>
            )}
          />
          <Autocomplete
            options={wallets?.list}
            getOptionLabel={(option) => option.name || ""}
            value={sourceWallet}
            onChange={(event, newValue) => {
              setSourceWallet(newValue);
              setDesignationWallet(null);
              setAmount("");
              dispatch(resetwalletListAction({ list: [] }));
              setError({
                ...error,
                sourceWallet: "",
                amount: ""
              });
            }}
            renderInput={(params) => <TextField {...params} label="Select Wallet" placeholder="type to search...." />}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <span>{option.name}</span>
                <span style={{ color: "#64748B" }}>{option.participant?.email}</span>
              </li>
            )}
          />
          {error.sourceWallet && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.sourceWallet}
            </div>
          )}
          {sourceWallet && <div className={styles.success}>balance: {sourceWallet?.balance}</div>}
        </div>
        <div className={styles.toWallet}>
          <h3>To Wallet</h3>
          <Autocomplete
            options={users?.list}
            getOptionLabel={(option) => option?.firstName}
            filterOptions={(x) => x}
            value={designationParticipant}
            onInputChange={handleUserChange}
            onChange={(event, newValue) => {
              setDesignationParticipant(newValue);
              setDesignationWallet(null);
              fetchWalletList(String(newValue?.userId));
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Participant" placeholder="type to search...." />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <span>{option?.firstName + " " + option?.lastName}</span>
                <span style={{ color: "#64748B" }}>{option?.email}</span>
              </li>
            )}
          />
          <Autocomplete
            options={wallets?.list}
            getOptionLabel={(option) => option.name || ""}
            value={designationWallet}
            onChange={(event, newValue) => {
              setDesignationWallet(newValue);
              dispatch(resetwalletListAction({ list: [] }));
              setAmount("");
              if (newValue?.id === sourceWallet?.id) {
                setError({
                  ...error,
                  designationWallet: "From wallet and To wallet must be different"
                });
              } else {
                setError({
                  ...error,
                  designationWallet: ""
                });
              }
            }}
            renderInput={(params) => <TextField {...params} label="To Wallet" placeholder="type to search...." />}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <span>{option.name}</span>
                <span style={{ color: "#64748B" }}>{option.participant?.email}</span>
              </li>
            )}
          />
          {error.designationWallet && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.designationWallet}
            </div>
          )}
          {!error.designationWallet && designationWallet && (
            <div className={styles.success}>balance: {designationWallet?.balance}</div>
          )}
        </div>
        <div>
          <div className={styles.amountCard} aria-live="polite">
            <div className={styles.title}>How much do you need?</div>
            <div className={styles.amount}>
              <div className={styles.amtValue} onClick={() => setAmount("10")}>
                $10
              </div>
              <div className={styles.amtValue} onClick={() => setAmount("20")}>
                $20
              </div>
              <div className={styles.amtValue} onClick={() => setAmount("50")}>
                $50
              </div>
              <div className={styles.amtValue} onClick={() => setAmount("100")}>
                $100
              </div>
            </div>
            <div className={styles.amountInput}>
              <input
                type="number"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                aria-label="Enter Amount"
              />
            </div>
          </div>
          {error.amount && (
            <div className={styles.error}>
              <ErrorOutlineIcon className={styles.errIcon} />
              {error.amount}
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          {onClose && (
            <Button variant="contained" className={styles.backBtn} onClick={handleBack}>
              Back
            </Button>
          )}
          <Button variant="contained" className={styles.continueBtn} onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
}
