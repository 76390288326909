/* eslint-disable no-nested-ternary */
// StackedCarousel.tsx
import React, { ReactNode, useState, useEffect, useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./StackedCarousel.module.scss";

interface StackedCarouselProps {
  children: ReactNode[];
  containerClassName?: string;
  carouselClassName?: string;
  autoRotate?: boolean;
  rotationInterval?: number;
  onCardChange?: (_indexes: { currentIndex: number; nextIndex: number; previousIndex: number }) => void;
  leftButton?: ReactNode;
  rightButton?: ReactNode;
}

const StackedCarousel: React.FC<StackedCarouselProps> = ({
  children,
  containerClassName,
  carouselClassName,
  autoRotate = true,
  rotationInterval = 2000,
  onCardChange,
  leftButton,
  rightButton
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const [previousIndex, setPreviousIndex] = useState(children.length - 1);

  const handlePrevClick = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? children.length - 1 : prevIndex - 1));
    setNextIndex((prevIndex) => (prevIndex === 0 ? children.length - 1 : prevIndex - 1));
    setPreviousIndex((prevIndex) => (prevIndex === 0 ? children.length - 1 : prevIndex - 1));
  }, [children.length]);

  const handleNextClick = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === children.length - 1 ? 0 : prevIndex + 1));
    setNextIndex((prevIndex) => (prevIndex === children.length - 1 ? 0 : prevIndex + 1));
    setPreviousIndex((prevIndex) => (prevIndex === children.length - 1 ? 0 : prevIndex + 1));
  }, [children.length]);

  const handleCardTransition = useCallback(() => {
    handleNextClick();
  }, [handleNextClick]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNextClick,
    onSwipedRight: handlePrevClick,
    trackMouse: true,
    trackTouch: true,
    delta: 10
  });

  useEffect(() => {
    onCardChange?.({ currentIndex, nextIndex, previousIndex });
    const transitionInterval = setInterval(() => {
      autoRotate && handleCardTransition();
    }, rotationInterval);

    return () => clearInterval(transitionInterval);
  }, [autoRotate, currentIndex, handleCardTransition, nextIndex, onCardChange, previousIndex, rotationInterval]);

  return (
    <div className={`${styles.stackedCarousel} ${containerClassName}`}>
      {leftButton && (
        <button className={styles.stackedCarouselButton} onClick={handlePrevClick} aria-label="previous card">
          {leftButton}
        </button>
      )}
      <div className={`${styles.stackedCarouselContainer} ${carouselClassName}`} {...handlers}>
        <div className={styles.cardSummary}>
          {children.map((card, index) => (
            <div
              key={index}
              className={`${styles.card} ${
                index === currentIndex
                  ? styles.active
                  : index === nextIndex
                    ? styles.next
                    : index === previousIndex
                      ? styles.prev
                      : styles.inactive
              }`}
            >
              {card}
            </div>
          ))}
        </div>
      </div>
      {rightButton && (
        <button className={styles.stackedCarouselButton} onClick={handleNextClick} aria-label="next card">
          {rightButton}
        </button>
      )}
    </div>
  );
};

export default StackedCarousel;
