import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  createUser,
  type CreateUserRequest,
  searchUser,
  SuspendUserRequest,
  suspendUser,
  getUserByEmail,
  ConnectUserRequest,
  connectUser
} from "src/network/graphql/userService";
import { immediateToast } from "izitoast-react";
import { UserDetail } from "../userDetail/userDetailSlice";

export type Users = {
  id: string;
  initial: string;
  fullName: string;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  gender: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postCode: string;
  Country: string;
  role: {
    organisation_id: string;
    value: string;
  }[];
  currentOrganisationRole: string;
  active: boolean;
  lastActive: string;
  userId: string;
  createdAt: string;
};

const initialUsers: Users[] = [];

const initialUserData: UserDetail = {
  user: {
    id: 0,
    cognitoUserName: "",
    displayId: "",
    active: false,
    email: "",
    userName: "",
    initial: "Mr",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    gender: "male",
    state: "",
    city: "",
    line1: "",
    line2: "",
    zipcode: "",
    country: "",
    role: [],
    accessCode: "",
    fullName: "",
    guardianId: 0,
    kycStatus: "Fail",
    profileImage: ""
  },
  guardian: null,
  canDoActiveInactive: false,
  canEditRole: false,
  canEditGuardian: false,
  canEditKycStatus: false
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLoading: false,
    userDataLoading: false,
    error: null,
    users: {
      list: initialUsers,
      total: 0
    },
    userData: initialUserData,
    showSetPassword: false,
    setPasswordLoading: false
  },
  reducers: {
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    addUserStart: (state) => {
      state.userLoading = true;
    },
    addUserSuccess: (state) => {
      state.userLoading = false;
    },
    addUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    connectUserStart: (state) => {
      state.userLoading = true;
    },
    connectUserSuccess: (state) => {
      state.userLoading = false;
    },
    connectUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    suspendUserStart: (state) => {
      state.userLoading = true;
    },
    suspendUserSuccess: (state) => {
      state.userLoading = false;
    },
    suspendUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    getUserByEmailStart: (state) => {
      state.userDataLoading = true;
    },
    getUserByEmailSuccess: (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload ?? initialUserData;
    },
    getUserByEmailFail: (state, action) => {
      state.userDataLoading = false;
      state.userData = initialUserData;
      state.error = action.payload;
    },
    setShowSetPassword: (state, action) => {
      state.showSetPassword = action.payload;
    },
    setSetPasswordLoading: (state, action) => {
      state.setPasswordLoading = action.payload;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  addUserStart,
  addUserSuccess,
  addUserFail,
  connectUserStart,
  connectUserSuccess,
  connectUserFail,
  suspendUserStart,
  suspendUserSuccess,
  suspendUserFail,
  getUserByEmailStart,
  getUserByEmailFail,
  getUserByEmailSuccess
} = userSlice.actions;

export const { setSetPasswordLoading, setShowSetPassword } = userSlice.actions;

export const fetchUserListAction = (data: {
  isActive?: boolean | null;
  email?: string;
  fullName?: string;
  organisationId?: string | null;
  limit?: number;
  from?: number;
  role?: string;
}) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchUser({
        user_filter: {
          is_active: data.isActive,
          email: data.email,
          full_name: data.fullName,
          organisation_id: data.organisationId,
          role: data.role
        },
        size: data.limit,
        from: data.from
      });

      const structuredResponse = response.data.search_user.user_listing.map((user: any) => ({
        id: user?.display_id,
        firstName: user?.attributes?.first_name,
        lastName: user?.attributes?.last_name,
        email: user?.email,
        role: user?.current_organisation_role,
        status: user?.is_active,
        userId: user?.user_id,
        createdAt: user?.created_at
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const addUserAction = (data: CreateUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addUserStart());
    try {
      const response = (await createUser(data)) as unknown as { errors?: Error[] };

      dispatch(addUserSuccess());
      immediateToast("success", {
        message: "User Successfully Added.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
      return response;
    } catch (error) {
      dispatch(addUserFail(error));
    }
  };
};

export const connectUserAction = (data: ConnectUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(connectUserStart());
    try {
      const response = await connectUser(data);

      dispatch(connectUserSuccess());
      immediateToast("success", {
        message: `User Successfully Connected.`,
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
      return response;
    } catch (error) {
      dispatch(connectUserFail(error));
    }
  };
};

export const suspendUserAction = (data: SuspendUserRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(suspendUserStart());
    try {
      const response = await suspendUser(data);

      dispatch(suspendUserSuccess());
      immediateToast("success", {
        message: "User successfully unlinked from the organization.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();
      return response;
    } catch (error) {
      dispatch(suspendUserFail(error));
    }
  };
};

export const getUserByEmailAction = (email: string, cb: () => void, fcb: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getUserByEmailStart());

    try {
      const response = await getUserByEmail(email);
      const user = response.data.get_user;

      dispatch(
        getUserByEmailSuccess({
          user: {
            id: user.user_id,
            displayId: user.display_id,
            email: user.email
          }
        })
      );
      if (cb) cb();
    } catch (error) {
      dispatch(getUserByEmailFail(error));
      if (fcb) fcb();
    }
  };
};

export default userSlice.reducer;
